import { json } from '@remix-run/node';
import { useFetcher, useLoaderData } from '@remix-run/react';

import { List } from '@mantine/core';
import { IconHeartbeat, IconHourglassEmpty } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ALERT_CODES } from '@/api/enums';
import { getForkliftsList } from '@/api/map.server';
import { GeneralErrorBoundary } from '@/components/ErrorBoundary';
import { ForkliftListItemLink, SecondaryText } from '@/components/ForkliftListItem';
import { useInterval } from '@/hooks';
import { MapDrawerBody } from '@/lib/OpenLayers';
import { getDurationFromTimestamp } from '@/utils/misc';

export const loader = async () => {
  const vehicles = await getForkliftsList();

  const forkliftsList = vehicles.data.map((item) => {
    const stopDuration = item.activeAlertDto.find((activeAlert) => activeAlert.code === ALERT_CODES.STOP)?.timestamp;
    return {
      id: item.id.toString(),
      serial: item.serial,
      label: item.name,
      state: item.alert,
      stopDuration: stopDuration ? getDurationFromTimestamp(stopDuration) : null,
    };
  });

  return json(forkliftsList);
};

const Route = (): JSX.Element => {
  const forklifts = useLoaderData<typeof loader>();
  const { t } = useTranslation();
  const { load, state: fetchState, data: fetchedData } = useFetcher<typeof loader>();
  const realTimeData = fetchedData ?? forklifts;

  const fetchDetails = () => {
    if (document.visibilityState === 'visible' && fetchState === 'idle') {
      load('.');
    }
  };

  useInterval(fetchDetails, Number.parseInt(globalThis.ENV.VEHICLE_LIST_INTERVAL_TIME, 10));

  const getDuration = (dur: number) => {
    const durObj = dayjs.duration(dur, 'milliseconds');
    return `${Math.trunc(durObj.asHours())}h ${durObj.format('mm[min]')}`;
  };

  return (
    <MapDrawerBody>
      <List listStyleType='none'>
        {realTimeData.map((forklift) => {
          const id = forklift.id;
          return (
            <ForkliftListItemLink
              key={id}
              to={`./${id}`}
              vehicleId={id}
              prefetch='intent'
              label={forklift.label}
              secondaryText={
                <>
                  <SecondaryText
                    icon={<IconHeartbeat />}
                    text={t(`alertsTypes.${forklift.state}`, { defaultValue: t('alertsTypes.Unknown') })}
                  />
                  {forklift.stopDuration && (
                    <SecondaryText icon={<IconHourglassEmpty />} text={getDuration(forklift.stopDuration)} />
                  )}
                </>
              }
            />
          );
        })}
      </List>
    </MapDrawerBody>
  );
};
export default Route;

export const ErrorBoundary = GeneralErrorBoundary;
